import React from 'react';
import ReactDOM from 'react-dom';
import ContactForm from "./components/ContactForm";

document.addEventListener("DOMContentLoaded", () => {

	let doc = document;
	let body = doc.querySelector('body');
	let html = doc.querySelector('html');

	if (doc.getElementById('contact-form')) {
		ReactDOM.render(<ContactForm />, doc.getElementById('contact-form'));
	}

});