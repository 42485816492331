import React, {useRef, useState, useEffect} from "react";

const ContactForm = props => {

	const [sent, setSent] = useState(false);
	const [sending, setSending] = useState(false);

	const contactForm = useRef(null);
	const name = useRef(null);
	const surname = useRef(null);
	const email = useRef(null);
	const message = useRef(null);
	const modalMessageForm = useRef(null);

	const validateEmail = (email) => {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	useEffect(() => {
		if (sent) {
			let timerOne = setTimeout(() => {
				modalMessageForm.current.classList.add('fadeOut');
			}, 2000)

			let timerTwo = setTimeout(() => {
				setSent(false);
			}, 3000)

			return () => {
				clearTimeout(timerOne);
				clearTimeout(timerTwo);
			}
		}
	}, [sent])

	const handleSubmit = async (event) => {

		event.preventDefault();
		name.current.parentElement.classList.remove('error');
		void name.current.parentElement.offsetWidth;
		surname.current.parentElement.classList.remove('error');
		void surname.current.parentElement.offsetWidth;
		email.current.parentElement.classList.remove('error');
		void email.current.parentElement.offsetWidth;
		message.current.parentElement.classList.remove('error');
		void message.current.parentElement.offsetWidth;
		let valid = true;
		if (name.current.value == '') {
			name.current.parentElement.classList.add('error');
			valid = false;
		}
		if (surname.current.value == '') {
			surname.current.parentElement.classList.add('error');
			valid = false;
		}
		if (!validateEmail(email.current.value)) {
			email.current.parentElement.classList.add('error');
			valid = false;
		}
		if (message.current.value == '') {
			message.current.parentElement.classList.add('error');
			valid = false;
		}

		if (valid) {
			setSending(true);
			const formdata = new FormData(event.target);

			const response = await fetch('/api/contact', {
				'method': 'POST',
				body: formdata
			});
			const data = await response.json();
			if (data) {
				let msg = data.msg;
				let code = data.code;
				if (code == 1) {
					contactForm.current.reset();
					setSent(true);
					setSending(false);

				} else {
					alert(msg);
				}
			}

		}
	}

	return (
		<>
			<form className="w-full max-w-3xl" method="post" onSubmit={handleSubmit} ref={contactForm}>
				<div className="flex flex-wrap -mx-3 mb-6">
					<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
						<label htmlFor="name" className="form-label">Meno <span
							className="text-red-500">*</span></label>
						<input ref={name} id="name" type="text" className="form-control" name="name"
						       placeholder="Ján" />
						<p className="hidden mt-3 text-red-500 text-xs italic">Prosím zadajte meno.</p>
					</div>
					<div className="w-full md:w-1/2 px-3">
						<label htmlFor="surname" className="form-label">Priezvisko <span
							className="text-red-500">*</span></label>
						<input ref={surname} id="surname" type="text" className="form-control"
						       name="surname" placeholder="Novák" />
						<p className="hidden mt-3 text-red-500 text-xs italic">Prosím zadajte priezvisko.</p>
					</div>
				</div>

				<div className="flex flex-wrap -mx-3 mb-6">
					<div className="w-full px-3">
						<label htmlFor="email" className="form-label">E-mail <span
							className="text-red-500">*</span></label>
						<input ref={email} id="email" type="text" className="form-control"
						       name="email"
						       placeholder="@" />
						<p className="hidden mt-3 text-red-500 text-xs italic">Prosím zadajte email.</p>
					</div>
				</div>

				<div className="row">
					<div className="form-group col-sm-12">
						<label htmlFor="text" className="form-label">Vaša správa <span className="text-red-500">*</span></label>
						<textarea ref={message}
						          placeholder="Text správy..."
						          className="no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
						          name="text"
						          id="text"></textarea>
						<p className="hidden mt-3 text-red-500 text-xs italic">Prosím vyplňte správu.</p>
					</div>
				</div>
				<div className="md:flex md:items-center">
					<div className="md:w-1/3">
						<button disabled={sending}
						        type="submit"
						        className="shadow bg-green-500 hover:bg-green-300 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
						>
							{sending ? 'Odosielanie...' : 'Odoslať'}
						</button>
					</div>
				</div>

			</form>
			{sent &&
			<section className="modal-message-form animated fadeIn" ref={modalMessageForm}>
				<div className="circle-loader load-complete">
					<div className="checkmark draw"></div>
				</div>
				<h2>Ďakujeme</h2>
			</section>
			}
		</>
	);

}


export default ContactForm;
